<!--
 * @Author: kenneth
 * @Date: 2024-03-12 14:52:52
 * @Last Modified by: danteclericuzio
 * @Last Modified time: 2024-08-21 13:23:14
-->

<template>
    <div>
        <div class="beware-of-scams d-none d-md-block">
            <div class="beware-of-scams-box ec-container">
                <div class="text-wrap">
                    <ec-image :webp="require('./img/robot-beware.webp')">
                        <img
                            src="./img/robot-beware.png"
                            class="text-title"
                        >
                    </ec-image>
                    <div class="title">
                        {{ getI18n('title') }}
                    </div>
                    <div class="text">
                        {{ getI18n('tip') }}
                    </div>
                    <div class="text">
                        {{ getI18n('security') }}
                    </div>
                    <div
                        class="more"
                        @click="goPage('faqBeware')"
                    >
                        {{ getI18n('more') }}
                    </div>
                </div>
                <div class="video-wrap">
                    <video
                        ref="video"
                        class="video-box"
                        :src="showWhatsappLink"
                        controls
                    />
                    <template v-if="!isPlay">
                        <ec-image :webp="require('./img/beware-of-scams-thumbnail.webp')">
                            <img
                                src="./img/beware-of-scams-thumbnail.png"
                                class="ph"
                            >
                        </ec-image>
                        <img
                            src="./img/play.svg"
                            class="play"
                            @click="playVideo"
                        >
                    </template>
                </div>
            </div>
        </div>

        <div class="beware-of-scams-mb">
            <ec-image :webp="require('./img/robot-beware.webp')">
                <img
                    src="./img/robot-beware.png"
                    class="text-title"
                >
            </ec-image>
            <div class="title">
                {{ getI18n('title') }}
            </div>
            <div class="text-th6">
                {{ getI18n('tip') }}
            </div>
            <div class="text-th6">
                {{ getI18n('security') }}
            </div>
            <div class="video-wrap">
                <video
                    v-show="isPlay"
                    ref="videoMb"
                    class="video-box"
                    :src="showWhatsappLink"
                    controls
                />
                <template v-if="!isPlay">
                    <ec-image :webp="require('./img/beware-of-scams-thumbnail.webp')">
                        <img
                            src="./img/beware-of-scams-thumbnail.png"
                            class="ph"
                        >
                    </ec-image>
                    <img
                        src="./img/play.svg"
                        class="play"
                        @click="playVideo"
                    >
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BewareOfScams',

    data() {
        return {
            isPlay: false,
            isMobile: false
        };
    },

    computed: {
        showWhatsappLink() {
            const showWhatsapp = this.$app.getBusinessData('showWhatsapp');

            return showWhatsapp ? 'https://indoi18n-web-cdn.fintopia.tech/upload/admin/2691c81c-b390-451d-a65f-387272ab65e2.mp4' : 'https://ec-cdn.fintopia.tech/upload/admin/lqF2NiI_UhmHoFeYXwrJYnkcy2Xq.mov';
        }
    },

    mounted() {
        this.isMobile = window.innerWidth <= 768;

        window.addEventListener('resize', this.calcMobile);

        this.$nextTick(() => {
            if (this.$refs.video) {
                this.$refs.video.load();
            }
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.calcMobile);
    },

    methods: {
        calcMobile() {
            this.isMobile = window.innerWidth <= 768;
        },

        getI18n(key, params) {
            return this.$t(`homeNew.beware-of-scams.${key}`, params);
        },

        playVideo() {
            this.isPlay = true;

            this.$nextTick(() => {
                if (!this.isMobile) {
                    this.$refs.video.play();

                    return;
                }

                this.$refs.videoMb.play();
            });
        },

        goPage(name) {
            this.$router.push({name});
        }
    }
};
</script>

<style lang="scss" scoped>
.beware-of-scams {
    position: relative;
    z-index: 3;
    margin-top: -1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: linear-gradient(162.74deg, #f8f8f8 -0.08%, #e3eaf3 90.71%);

    .beware-of-scams-box {
        box-sizing: border-box;
        padding-top: 1rem;
        padding-bottom: 2rem;
        display: flex;

        .video-wrap {
            width: 7.12rem;
            min-width: 7.12rem;
            height: 4rem;
            border-radius: 0.32rem;
            overflow: hidden;
            position: relative;

            .video-box {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .ph {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .play {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 0.6rem;
                height: 0.6rem;
            }
        }

        .text-wrap {
            margin-right: 0.5rem;

            .title {
                color: #1db839;
                margin-top: 0.2rem;
                font-family: Mozaic HUM;
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.32px;
            }

            .text-title {
                width: 1.16rem;
                height: 1.12rem;
            }

            .text {
                margin-top: 0.1rem;
                color: #000;
                font-family: Mozaic HUM;
                font-size: 0.16rem;
                font-style: normal;
                font-weight: 400;
                line-height: 0.24rem;
            }

            .more {
                margin-top: 0.6rem;
                width: 1.4rem;
                height: 0.4rem;
                border-radius: 0.3rem;
                border: 1px solid #1e1e1e;
                color: #fff;
                text-align: center;
                font-size: 0.14rem;
                font-family: Quicksand;
                font-weight: 700;
                line-height: 0.35rem;
                background-color: #0f0f0f;
                cursor: pointer;

                &:hover,
                &:active {
                    background-color: #333;
                }
            }
        }
    }
}

.beware-of-scams-mb {
    @media screen and (min-width: $screen-md) {
        opacity: 0;
        height: 0;
    }

    .title {
        margin-top: 0.2rem;
        color: #1db839;
        font-family: Mozaic HUM;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    .text-title {
        width: 0.58rem;
        height: 0.56rem;
    }

    .text-th6 {
        margin-top: 0.05rem;
        color: #000;
        font-family: Mozaic HUM;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.24rem;
    }

    .video-wrap {
        height: 1.88rem;
        margin-top: 0.1rem;
        margin-bottom: 0.32rem;
        overflow: hidden;
        border-radius: 0.16rem;
        position: relative;

        .video-box {
            width: 100%;
            height: 100%;
        }

        .ph {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0.6rem;
            height: 0.6rem;
        }
    }
}
</style>
