<!--
 * @Author: zhaoyang
 * @Date: 2023-06-28 19:55:46
 * @Last Modified by: danteclericuzio
 * @Last Modified time: 2024-08-21 15:10:07
-->

<template>
    <div>
        <div class="home-user-pc  d-none d-md-block">
            <swiper
                ref="mySwiper"
                :options="swiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in userList"
                    :key="index"
                >
                    <div class="slide-box">
                        <div class="slide">
                            <div class="left">
                                <ec-image :webp="require('./img/yy.webp')">
                                    <img
                                        src="./img/yy.png"
                                        class="yy"
                                    >
                                </ec-image>
                                <ec-image :webp="require('./img/yy1.webp')">
                                    <img
                                        src="./img/yy1.png"
                                        class="yy1"
                                    >
                                </ec-image>
                                <ec-image :webp="require('./img/yy2.webp')">
                                    <img
                                        src="./img/yy2.png"
                                        class="yy2"
                                    >
                                </ec-image>
                                <ec-image :webp="item.iconWebp">
                                    <img
                                        class="left-img"
                                        :src="item.icon"
                                    >
                                </ec-image>
                                <ec-image :webp="require('./img/hart.webp')">
                                    <img
                                        class="hart"
                                        src="./img/hart.png"
                                    >
                                </ec-image>
                            </div>
                            <div class="right">
                                <div class="top-title">
                                    <div>{{ getI18n('titleTop') }}</div>
                                    <div>{{ getI18n('titleBottom') }}</div>
                                </div>
                                <div class="bottom-box">
                                    <div class="bottom-title-box">
                                        <ec-image :webp="item.iconWebp">
                                            <img
                                                :src="item.icon"
                                                class="bottom-img"
                                            >
                                        </ec-image>
                                        <div class="bottom-title">
                                            <div>
                                                {{ item.name }}
                                            </div>
                                            <div class="bottom-desc">
                                                {{ item.profession }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-content">
                                        {{ item.said }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div
                    slot="pagination"
                    class="swiper-pagination"
                />
            </swiper>
            <div class="container-box">
                <div class="ec-container container-wrap">
                    <img
                        src="./img/left-arrow.svg"
                        class="swiper-button-prev-self prev-self"
                    >
                    <img
                        class="swiper-button-next-self next-self"
                        src="./img/right-arrow.svg"
                    >
                </div>
            </div>
        </div>
        <div class="home-user-mobile d-block d-md-none">
            <div class="slide-title">
                {{ getI18n('title') }}
            </div>
            <swiper
                ref="mySwiper"
                :options="swiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in userList"
                    :key="index"
                >
                    <div class="slide-box">
                        <div class="bottom-box">
                            <div class="bottom-title-box">
                                <ec-image :webp="item.iconWebp">
                                    <img
                                        :src="item.icon"
                                        class="bottom-img"
                                    >
                                </ec-image>
                                <div class="bottom-title">
                                    <div>
                                        {{ item.name }}
                                    </div>
                                    <div class="bottom-desc">
                                        {{ item.profession }}
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-content">
                                {{ item.said }}
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div
                    slot="pagination"
                    class="swiper-pagination"
                />
            </swiper>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

import User1 from './img/user1.png';
import User1Webp from './img/user1.webp';
import User2 from './img/user2.png';
import User2Webp from './img/user2.webp';
import User3 from './img/user3.png';
import User3Webp from './img/user3.webp';

export default {
    name: 'HomeUser',

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            swiperOptions: {
                autoplay: true,
                loop: true,
                pagination: {
                    bulletActiveClass: 'my-bullet-active',
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next-self',
                    prevEl: '.swiper-button-prev-self'
                }
            }
        };
    },

    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },

        userList() {
            const UserImg = [User1, User2, User3];
            const UserImgWebp = [User1Webp, User2Webp, User3Webp];
            const {getI18n} = this;

            return getI18n('userList').map((item, idx) => ({
                ...item,
                icon: UserImg[idx],
                iconWebp: UserImgWebp[idx]
            }));
        }
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`homeNew.homeUser.${key}`, params);
        }
    }
};
</script>

<style lang="scss">
.home-user-pc,
.home-user-mobile {
    .swiper-slide {
        transform: translate3d(0, 0, 0);
    }

    .swiper-pagination-bullet {
        background-color: #424242;
        opacity: 1;
    }

    .swiper-pagination-bullets {
        bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
            bottom: 0.4rem;
        }

        .swiper-pagination-bullet {
            width: 0.08rem;
            height: 0.08rem;
        }

        .my-bullet-active {
            width: 0.12rem;
            height: 0.12rem;
            background-color: #1db839;
            opacity: 1;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~easycash/common/style/style";

.home-user-pc {
    z-index: 1;
    position: relative;
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    background-size: 80% 85%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url("./img/user-bg.png");
    background-color: #000;
    margin-top: -1.1rem;

    @media screen and (max-width: $screen-md) {
        margin-top: -0.24rem;
    }

    .slide-box {
        width: 100%;
        height: 6rem;

        .slide {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .left {
                width: 3.8rem;
                height: 3.8rem;
                position: relative;

                .left-img {
                    z-index: 1;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 0.24rem;
                }

                .hart {
                    z-index: 2;
                    width: 0.82rem;
                    position: absolute;
                    left: -0.4rem;
                    top: 0.6rem;
                }

                .yy {
                    width: 3.75rem;
                    height: 3.25rem;
                    position: absolute;
                    top: 0;
                    left: -0.9rem;
                }

                .yy1 {
                    width: 3.75rem;
                    height: 3.45rem;
                    position: absolute;
                    top: 0;
                    left: -0.6rem;
                }

                .yy2 {
                    width: 3.75rem;
                    height: 3.59rem;
                    position: absolute;
                    top: 0;
                    left: -0.3rem;
                }
            }

            .right {
                margin-left: 0.6rem;

                .top-title {
                    color: #fff;
                    font-size: 0.56rem;
                    font-weight: 700;
                    line-height: 0.72rem;
                }

                .bottom-box {
                    margin-top: 0.3rem;
                    color: #fff;
                    box-sizing: border-box;
                    padding: 0.3rem 0.3rem 0.46rem;
                    border-radius: 0.32rem;
                    background: #3fca58;

                    .bottom-title-box {
                        display: flex;
                        width: 2.46rem;

                        .bottom-img {
                            min-width: 0.48rem;
                            min-height: 0.48rem;
                            width: 0.48rem;
                            height: 0.48rem;
                            border-radius: 50%;
                            background: green;
                        }

                        .bottom-title {
                            font-size: 0.18rem;
                            font-weight: 700;
                            line-height: 0.3rem;
                            margin-left: 0.1rem;

                            .bottom-desc {
                                font-size: 0.15rem;
                                font-weight: 600;
                                line-height: 0.15rem;
                            }
                        }
                    }

                    .bottom-content {
                        margin-top: 0.2rem;
                        width: 2.46rem;
                        font-size: 0.16rem;
                        font-weight: 400;
                        line-height: 0.2rem;
                    }
                }
            }
        }
    }

    .container-box {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        .container-wrap {
            height: 100%;
            position: relative;

            .swiper-button-prev-self,
            .swiper-button-next-self {
                width: 0.56rem;
                height: 0.56rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            .prev-self {
                left: 0;
            }

            .next-self {
                right: 0;
            }
        }
    }
}

.home-user-mobile {
    background-color: #131313;
    border-radius: 0.24rem 0.24rem 0 0;
    overflow: hidden;

    .slide-title {
        color: #fff;
        font-size: 0.24rem;
        font-weight: 700;
        line-height: 0.24rem;
        margin: 0.4rem 0 0 0.2rem;
    }

    .slide-box {
        box-sizing: border-box;
        padding: 0 0.2rem;
        width: 100%;
        height: 3.56rem;

        .bottom-box {
            margin-top: 0.2rem;
            color: #fff;
            box-sizing: border-box;
            height: 2.4rem;
            padding: 0.3rem 0.2rem 0.2rem;
            border-radius: 0.16rem;
            background: #3fca58;

            .bottom-title-box {
                display: flex;

                .bottom-img {
                    min-width: 0.48rem;
                    min-height: 0.48rem;
                    width: 0.48rem;
                    height: 0.48rem;
                    border-radius: 50%;
                    background: green;
                }

                .bottom-title {
                    font-size: 0.18rem;
                    font-weight: 700;
                    line-height: 0.3rem;
                    margin-left: 0.1rem;

                    .bottom-desc {
                        font-size: 0.15rem;
                        font-weight: 600;
                        line-height: 0.15rem;
                    }
                }
            }

            .bottom-content {
                margin-top: 0.2rem;
                font-size: 0.16rem;
                font-weight: 400;
                line-height: 0.2rem;
            }
        }
    }
}
</style>
