<!--
 * @Author: zhaoyang
 * @Date: 2023-06-29 14:38:01
 * @Last Modified by: danteclericuzio
 * @Last Modified time: 2024-08-21 14:39:12
-->

<template>
    <div class="why">
        <div class="why-box ec-container">
            <div class="why-title">
                {{ getI18n('title') }}
            </div>
            <div class="choose-box">
                <div
                    v-for="(item, index) in chooseList"
                    :key="index"
                    class="choose-item"
                >
                    <img
                        :src="item.icon"
                        class="choose-icon"
                    >
                    <div class="choose-content-wrap">
                        <div class="choose-title">
                            {{ item.title }}
                        </div>
                        <div class="choose-desc">
                            {{ item.desc }}
                        </div>
                        <div
                            v-if="index!==4"
                            class="border-r"
                        />
                    </div>
                </div>
                <div class="robot" />
            </div>
        </div>
        <div class="feature-box ec-container">
            <div class="why-title">
                {{ getI18n('featureTitle') }}
            </div>
            <div class="feature-wrap feature-wrap-left">
                <div class="feature-text">
                    <div class="feature-title">
                        {{ getI18n('featureOneTitle') }}
                    </div>
                    <div class="feature-content">
                        <div class="left">
                            <div
                                v-for="item in getI18n('featureContentLeft')"
                                :key="item.title"
                                class="box"
                            >
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="text">
                                    {{ item.text }}
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div
                                v-for="item in getI18n('featureContentRight')"
                                :key="item.title"
                                class="box"
                            >
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="text">
                                    {{ item.text }}
                                </div>
                            </div>
                            <div
                                class="more"
                                @click="goPage('borrow')"
                            >
                                {{ getI18n('more') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="feature-wrap feature-wrap-right">
                <div class="feature-text feature-text-bottom">
                    <div class="feature-title">
                        {{ getI18n('featureTwoTitle') }}
                    </div>
                    <div class="feature-content feature-content-bottom">
                        <div class="left left-bottom">
                            <div
                                v-for="item in getI18n('featureContentBottomLeft')"
                                :key="item.title"
                                class="box"
                            >
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="text">
                                    {{ item.text }}
                                </div>
                            </div>
                        </div>
                        <div class="right right-bottom">
                            <div
                                v-for="item in getI18n('featureContentBottomRight')"
                                :key="item.title"
                                class="box"
                            >
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="text">
                                    {{ item.text }}
                                </div>
                            </div>
                            <div
                                class="more"
                                @click="goPage('lend')"
                            >
                                {{ getI18n('more') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Afpi from './img/afpi.svg';
import Ai from './img/ai.svg';
import Award from './img/award.svg';
import Dun from './img/dun.svg';
import Ojk from './img/ojk.svg';

const IconList = [Ojk, Afpi, Ai, Award, Dun];

export default {
    name: 'WhyChooseUs',

    computed: {
        chooseList() {
            return this.getI18n('chooseList').map((item, index) => ({...item, icon: IconList[index]}));
        }
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`homeNew.whyChooseUs.${key}`, params);
        },

        goPage(name) {
            this.$router.push({name});
        }
    }
};
</script>

<style lang="scss" scoped>
.why {
    position: relative;
    z-index: 1;
    color: #000;
    border-radius: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
    padding: 1rem 0 2.2rem;
    background-color: #fff;
    margin-top: -1.1rem;

    @media screen and (max-width: $screen-md) {
        border-radius: 0.24rem;
        padding: 0.4rem 0 0.64rem;
        margin-top: -0.24rem;
    }

    .why-title {
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 0.8rem;

        @media screen and (max-width: $screen-md) {
            font-size: 0.24rem;
            line-height: 0.7rem;
        }
    }

    .why-box {
        .choose-box {
            margin-top: 0.26rem;
            position: relative;
            border-radius: 0.32rem;
            background: linear-gradient(181deg, rgba(227, 234, 243, 0.4) 0%, #e3eaf3 100%);
            box-sizing: border-box;
            padding: 0.5rem 0.4rem 0.4rem;
            display: flex;

            @media screen and (max-width: $screen-md) {
                margin-top: 0.23rem;
                border-radius: 0;
                background: unset;
                padding: 0;
                flex-direction: column;
            }

            .choose-item {
                box-sizing: border-box;
                padding-right: 0.15rem;
                flex: 1;

                &:nth-child(n+2) {
                    padding-left: 0.3rem;
                }

                .choose-icon {
                    display: inline-block;
                    width: auto;
                    height: 0.32rem;

                    @media screen and (max-width: $screen-md) {
                        height: 0.26rem;
                    }
                }

                .choose-content-wrap {
                    position: relative;

                    .choose-title {
                        font-size: 0.16rem;
                        font-weight: 700;
                        line-height: 0.24rem;
                    }

                    .choose-desc {
                        margin-top: 0.08rem;
                        font-size: 0.14rem;
                        font-weight: 300;
                        line-height: 0.18rem;

                        @media screen and (max-width: $screen-md) {
                            margin-top: 0;
                        }
                    }

                    .border-r {
                        width: 1px;
                        height: 1.2rem;
                        background: #fff;
                        position: absolute;
                        right: -0.15rem;
                        top: 0;

                        @media screen and (max-width: $screen-md) {
                            width: 0;
                        }
                    }
                }

                @media screen and (max-width: $screen-md) {
                    border-radius: 0.16rem;
                    background: linear-gradient(180deg, #f8f8f8 0%, #e3eaf3 100%);
                    padding: 0.2rem;
                    margin-top: 0.1rem;

                    &:nth-child(n+2) {
                        padding: 0.2rem;
                    }
                }
            }

            .robot {
                position: absolute;
                right: 0.2rem;
                top: -0.7rem;
                width: 1.4rem;
                height: 0.8rem;
                background-image: url("./img/robot.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;

                @media screen and (max-width: $screen-md) {
                    width: 1rem;
                    height: 0.58rem;
                    top: -0.43rem;
                    right: 0.1rem;
                }
            }
        }
    }

    .feature-box {
        box-sizing: border-box;
        padding: 1rem 0 0;

        @media screen and (max-width: $screen-md) {
            padding-top: 0.4rem;
        }

        .feature-wrap {
            box-sizing: border-box;
            margin-top: 0.6rem;
            border-radius: 0.32rem;
            background-repeat: no-repeat;
            background-size: 5rem 100%;

            @media screen and (max-width: $screen-md) {
                padding-top: 2.35rem;
                margin-top: 0.2rem;
                border-radius: 0.24rem;
                background-size: 100% 3.35rem;
            }

            .feature-text {
                overflow: hidden;
                text-align: right;
                box-sizing: border-box;
                padding: 0.52rem 0 0.74rem 0.92rem;
                margin-left: 4.4rem;
                border-radius: 0.32rem 0 0 0.32rem;
                background: linear-gradient(to right, #eef2f7, #fff);

                @media screen and (max-width: $screen-md) {
                    text-align: left;
                    margin-left: 0;
                    padding: 0.2rem 0.2rem 0.1rem;
                    border-radius: 0.14rem;
                    background: linear-gradient(180deg, #f8f8f8 0%, #e3eaf3 100%);
                }

                .feature-title {
                    color: #000;
                    font-size: 0.52rem;
                    font-weight: 300;
                    line-height: 0.56rem;

                    @media screen and (max-width: $screen-md) {
                        font-size: 18px;
                        line-height: 1;
                    }
                }

                .feature-content {
                    display: flex;
                    justify-content: flex-end;
                    color: #000;
                    text-align: right;
                    margin-top: 0.3rem;

                    @media screen and (max-width: $screen-md) {
                        text-align: left;
                        margin-top: 0.2rem;
                        justify-content: space-between;
                    }

                    .box {
                        margin-top: 0.4rem;

                        @media screen and (max-width: $screen-md) {
                            margin-top: 0;
                            margin-bottom: 0.1rem;
                        }

                        .title {
                            font-weight: 700;
                            font-size: 0.28rem;
                            line-height: 1;
                            text-transform: uppercase;

                            @media screen and (max-width: $screen-md) {
                                font-size: 0.14rem;
                            }
                        }

                        .text {
                            max-width: 3rem;
                            color: rgba(0, 0, 0, 0.7);
                            font-size: 0.16rem;
                            font-weight: 400;
                            line-height: 0.2rem;

                            @media screen and (max-width: $screen-md) {
                                font-size: 0.12rem;
                                line-height: 0.14rem;
                                margin-top: 0.05rem;
                            }
                        }
                    }

                    .left {
                        display: flex;
                        flex-direction: column;
                        box-sizing: border-box;

                        @media screen and (max-width: $screen-md) {
                            padding-right: 0.1rem;
                            width: 50%;
                        }
                    }

                    .left-bottom {
                        margin-right: 1rem;

                        @media screen and (max-width: $screen-md) {
                            margin-right: 0;
                        }
                    }

                    .right {
                        margin-left: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        @media screen and (max-width: $screen-md) {
                            width: 50%;
                            align-items: flex-start;
                            margin-left: 0;
                        }

                        .more {
                            margin-top: 0.36rem;
                            width: 0.79rem;
                            height: 0.4rem;
                            border-radius: 0.3rem;
                            border: 1px solid #1e1e1e;
                            color: #000;
                            text-align: center;
                            font-size: 0.14rem;
                            font-family: Quicksand;
                            font-weight: 400;
                            line-height: 0.4rem;
                            cursor: pointer;

                            &:hover,
                            &:active {
                                background-color: #f1f1f1;
                            }

                            @media screen and (max-width: $screen-md) {
                                margin-bottom: 0.1rem;
                                margin-top: auto;
                                width: 0.4rem;
                                height: 0.2rem;
                                font-size: 0.07rem;
                                border-radius: 0.1rem;
                                line-height: 0.2rem;
                            }
                        }
                    }

                    .right-bottom {
                        margin-left: 0;
                        align-items: flex-start;
                    }
                }

                .feature-content-bottom {
                    text-align: left;
                    justify-content: flex-start;
                }
            }

            .feature-text-bottom {
                text-align: left;
                box-sizing: border-box;
                padding: 0.52rem 0.92rem 0.74rem 0;
                margin-right: 4.4rem;
                margin-left: 0;
                border-radius: 0 0.32rem 0.32rem 0;
                background: linear-gradient(to right, #fff, #eef2f7);

                @media screen and (max-width: $screen-md) {
                    margin-right: 0;
                    padding: 0.2rem 0.2rem 0.1rem;
                    border-radius: 0.14rem;
                    background: linear-gradient(180deg, #f8f8f8 0%, #e3eaf3 100%);
                }
            }
        }

        .feature-wrap-left {
            background-image: url("./img/feature1.png");
            background-size: contain;
        }

        .feature-wrap-right {
            background-image: url("./img/feature2.png");
            background-position: 100% top;
            background-size: contain;
        }
    }
}

.bg-use-webp {
    .robot {
        background-image: url("./img/robot.webp") !important;
    }

    .feature-wrap-left {
        background-image: url("./img/feature1.webp") !important;
    }

    .feature-wrap-right {
        background-image: url("./img/feature2.webp") !important;
    }
}
</style>
